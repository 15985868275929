<script>
import OrderStatusesRepository from '@/api/OrderStatusesRepository'
import EventBus from '@/plugins/EventBus'

export default {
  name: 'OrdersListMixin',
  data () {
    return {
      orders: [],
      totalOrders: 0,
      loading: true,
      isCollapse: false,
      options: {
        page: 1,
        itemsPerPage: 20,
      },
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (newValue, oldValue) {
        if (this.isCollapse) {
          this.isCollapse = false
        } else {
          this.fetchOrders()
        }
      },
    },
    options: {
      handler (newValue, oldValue) {
        if (newValue.itemsPerPage !== oldValue.itemsPerPage) {
          this.fetchOrders()
        }
        if (newValue.page !== oldValue.page) {
          this.fetchOrders()
        }
      },
      deep: true,
    },
  },
  async mounted () {
    this.$refs.ordersList.$on('pagination', this.handlePagination)
    const instance = this
    EventBus.$on('orderStatusUpdated', ({ id, status }) => {
      const order = instance.orders.find(element => element.id === id)
      order.current_status = status
    })
  },
  methods: {
    fetchOrderStatuses (index) {
      return new Promise((resolve, reject) => {
        OrderStatusesRepository.index()
          .then(({ data }) => {
            const filters = data.filter(element => element.show)
            filters.forEach(element => {
              element.description = this.$t(element.description)
            })

            this.filters[index].options = filters
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    onPagination () {
      this.$on('pagination', this.handlePagination)
    },
    handlePagination (options) {
      this.options = options
    },
    fetchOrders () {
      const queryParams = {
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        search: this.search,
        with: 'entity,client,currentStatus',
      }
      for (const filter of this.filters) {
        let checkedOptions = filter.options.filter(option => {
          return option.checked === true
        })

        checkedOptions = checkedOptions.map(option => {
          return option.value
        })

        if (checkedOptions.length > 0) {
          queryParams[filter.queryParam] = checkedOptions.join()
        }
      }

      this.loading = true
      this.apiCall(queryParams)
        .then(({ data }) => {
          this.orders = data.data
          this.totalOrders = data.meta.total
          this.loading = false
        })
        .catch(() => {
        })
    },

    handleCollapse (filter) {
      this.isCollapse = true
      filter.collapsed = !filter.collapsed
    },
  },
}
</script>
