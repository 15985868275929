<template>
  <orders-list
    ref="ordersList"
    :headers="headers"
    :orders="orders"
    :loading="loading"
    :filters="filters"
    :options="options"
    :total-orders="totalOrders"
    :handle-collapse="handleCollapse"
  />
</template>

<script>

import EntityOrdersRepository from '@/api/EntityOrdersRepository'
import OrdersList from '@/views/dashboard/lists/OrdersList'
import OrdersListMixin from '@/views/dashboard/pages/OrdersIndex/components/OrdersListMixin'
import { capitalize } from '@/helpers/helpers'

export default {
  name: 'EntityOrdersListWrapper',
  components: { OrdersList },
  mixins: [OrdersListMixin],
  props: {
    entityId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      required: true,
    },
  },
  data () {
    return {
      headers: [
        { text: capitalize(this.$t('client')), value: 'client.name' },
        { text: capitalize(this.$t('status')), value: 'current_status' },
        { text: capitalize(this.$t('created')), value: 'created_at' },
        { text: capitalize(this.$t('actions')), value: 'action', sortable: false },
      ],
      filters: [
        {
          title: capitalize(this.$t('activity')),
          queryParam: 'open',
          collapsed: false,
          options: [
            {
              checked: true,
              value: 1,
              description: capitalize(this.$t('open')),
            },
            {
              checked: false,
              value: 0,
              description: capitalize(this.$t('finished')),
            },
          ],
        },
        {
          title: 'Status',
          queryParam: 'status',
          collapsed: false,
          options: [],
        },
      ],
    }
  },
  async created () {
    await this.fetchOrderStatuses(1)
  },
  methods: {
    apiCall (params) {
      return EntityOrdersRepository.index(this.entityId, params)
    },
  },
}
</script>

<style scoped>

</style>
