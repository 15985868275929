<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="3"
      >
        <base-material-card
          :header="false"
        >
          <check-box-filter
            v-for="filter in filters"
            :key="filter.title"
            :filters="filter"
            @collapse="handleCollapse(filter)"
          />
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <base-material-card
          icon="mdi-basket"
          class="px-5 py-3"
        >
          <v-data-table
            dense
            :headers="headers"
            :items="orders"
            :loading="loading"
            item-key="name"
            class="pt-8"
            loading-text="Loading... Please wait"
            disable-sort
            :no-data-text="$t('c.noDataText')"
            :server-items-length="totalOrders"
            :footer-props="{
              itemsPerPageText: $t('c.orderPerPage'),
              itemsPerPageOptions: [10,20,40]
            }"
            :options="options"
            @pagination="(obj) => $emit('pagination', obj)"
          >
            <template v-slot:item.current_status="{ item }">
              <change-status-button :order="item" />
            </template>
            <template v-slot:item.action="{ item }">
              <div class="my-2">
                <v-btn
                  class="primary"
                  small
                  @click="show(item.id)"
                >
                  {{ $t('details') }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CheckBoxFilter from '@/views/dashboard/components/CheckBoxFilter'
import ChangeStatusButton from '@/views/dashboard/components/ChangeStatusButton/ChangeStatusButton'

export default {
  name: 'OrdersList',
  components: { ChangeStatusButton, CheckBoxFilter },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    totalOrders: {
      type: Number,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
    handleCollapse: {
      type: Function,
      required: true,
    },
  },
  methods: {
    show (id) {
      this.$router.push({
        name: 'Order Show',
        params: {
          orderId: id,
        },
      })
    },
    humanizeString (input) {
      input = input.toLowerCase().replace(/[_-]+/g, ' ').replace(/\s{2,}/g, ' ').trim()
      input = input.charAt(0).toUpperCase() + input.slice(1)
      return input
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          orderPerPage: 'Orders per page',
          noDataText: 'Any order found',
        },

      },
      it: {
        c: {
          orderPerPage: 'Ordini per pagina',
          noDataText: 'Nessun ordine trovato',
        },
      },
    },
  },

}
</script>

<style scoped>

</style>
