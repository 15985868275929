import Repository from './Repository'

const resource = '/api/order-statuses'

export default {
  index () {
    return Repository.get(`${resource}`)
  },

}
