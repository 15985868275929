var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('base-material-card',{attrs:{"header":false}},_vm._l((_vm.filters),function(filter){return _c('check-box-filter',{key:filter.title,attrs:{"filters":filter},on:{"collapse":function($event){return _vm.handleCollapse(filter)}}})}),1)],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-basket"}},[_c('v-data-table',{staticClass:"pt-8",attrs:{"dense":"","headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading,"item-key":"name","loading-text":"Loading... Please wait","disable-sort":"","no-data-text":_vm.$t('c.noDataText'),"server-items-length":_vm.totalOrders,"footer-props":{
            itemsPerPageText: _vm.$t('c.orderPerPage'),
            itemsPerPageOptions: [10,20,40]
          },"options":_vm.options},on:{"pagination":function (obj) { return _vm.$emit('pagination', obj); }},scopedSlots:_vm._u([{key:"item.current_status",fn:function(ref){
          var item = ref.item;
return [_c('change-status-button',{attrs:{"order":item}})]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){return _vm.show(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }