import Repository from './Repository'

function buildUrl (entityId, orderId = null) {
    let url = `/api/entities/${entityId}/orders`
    if (orderId) {
        url = `/${url}/${orderId}`
    }

    return url
}

export default {
    index (entityId, params) {
        return Repository.get(buildUrl(entityId), { params })
    },
    store (entityId, body) {
        return Repository.post(buildUrl(entityId), body)
    },
}
