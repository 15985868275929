<template>
  <div>
    <v-row
      class="mb-4"
      style="cursor: pointer;"
      @click="$emit('collapse')"
    >
      <v-subheader>
        {{ filters.title }}
      </v-subheader>
      <v-icon
        v-if="filters.collapsed"
      >
        mdi-menu-up
      </v-icon>
      <v-icon
        v-else
      >
        mdi-menu-down
      </v-icon>
    </v-row>

    <div>
      <v-checkbox
        v-for="option in filters.options"
        v-show="!filters.collapsed"
        :key="option.description"
        v-model="option.checked"
        class="ma-0"
        :label="option.description"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBoxFilter',
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style scoped>

</style>
